import React, { useState } from 'react';
import { Virtuoso } from 'react-virtuoso';
import { Checkbox, Divider } from '@mui/material';

import { Container, MoreOptions, Placeholder } from '@components-new/atoms';

import { CustomStack, DataTableContainer, HoverWarranties, NoDataContainer, Row } from './DataTable_.style';
import { DataTableProps } from './DataTable.type';
import { t } from 'i18next';

const DataTable = ({
  loading,
  columns,
  rows,
  onRowClick,
  rowActions = null,
  maximumRowActionsDisplayed,
  checkboxSelection,
  allItemsSelection,
  setAllItemsSelection,
  checkRowSelection,
  handleRowSelection,
  headerComponents,
  noDataText,
  tableInventory = false,
  tableCustom = false,
  hideTable = false,
}: DataTableProps) => {
  // TODO!: Refactor this
  const [cctVisibility, setCctVisibility] = useState('hidden');

  if (rowActions !== null && rowActions.length) addRowActions(rowActions, columns, maximumRowActionsDisplayed);

  const inMenuAction = rowActions?.filter(rowAction => rowAction.showInMenu) ?? [];

  return (
    <>
      {!hideTable && (
        <DataTableContainer tableInventory={tableInventory} style={{ overflow: 'visible' }}>
          {headerComponents}
          {/* Header */}
          <Row headerRow headerRowInventory={tableInventory}>
            {checkboxSelection && (
              <Container key={`header-data-checkbox`} className='col-0-25' bolder center>
                <Checkbox
                  checked={allItemsSelection}
                  onClick={e => {
                    e.stopPropagation();
                  }}
                  onChange={e => setAllItemsSelection(prevState => !prevState)}
                  disabled={loading}
                />
              </Container>
            )}
            {columns.map((column: any, i: number) => {
              const { headerName, width, bolder, center, style } = column;
              return headerName === t('common.warranties') ? (
                <Container key={`header-data-${i}`} className={width} flex center={center}>
                  <HoverWarranties visibility={cctVisibility}>{t('inventory.CCTHover')}</HoverWarranties>
                  <span onMouseEnter={() => setCctVisibility('visible')} onMouseLeave={() => setCctVisibility('hidden')}>
                    {headerName}
                  </span>
                </Container>
              ) : (
                <Container key={`header-data-${i}`} className={width} flex bolder={bolder} center={center}>
                  <span style={style}>{headerName}</span>
                </Container>
              );
            })}
          </Row>
          {/* Content */}
          <Container className='container-fluid data-table' flex column p='0' overflowX='hidden' height='100%' width='100%'>
            {/* Loading State */}
            {loading ? (
              <>
                {[1, 2, 3, 4, 5].map(i => (
                  <React.Fragment key={`loading-fragment-${i}`}>
                    <Row key={`row-loading-${i}`}>
                      {checkboxSelection && <Container className={'col-0-25'}></Container>}
                      {columns.map((column, idx) => {
                        const { width, placeholder, center } = column;
                        return (
                          <Container key={`loading-column-${idx}`} className={width} flex center={center}>
                            {placeholder ? <Placeholder variant={placeholder.variant} width={placeholder.width} /> : null}
                          </Container>
                        );
                      })}
                    </Row>
                    {i < 5 && <Divider variant={!tableInventory ? 'middle' : undefined} />}
                  </React.Fragment>
                ))}
              </>
            ) : // No Data Loaded
            rows?.length === 0 ? (
              <NoDataContainer>{noDataText}</NoDataContainer>
            ) : (
              // Data Loaded
              <Virtuoso
                style={{ overflowX: 'hidden', overflowY: 'scroll', flex: '1 1 auto' }}
                data={rows}
                itemContent={(index, row) => {
                  return (
                    <>
                      <Row
                        key={`row-data-${index}`}
                        onClick={
                          tableInventory
                            ? undefined
                            : e => {
                                e.preventDefault();
                                onRowClick(row);
                              }
                        }
                        backlog={!row.draft && !row.active && !tableInventory && !tableCustom}
                        rowInventory={tableInventory}
                        hover={!tableInventory && true}>
                        {checkboxSelection && (
                          <Container className='col-0-25' bolder center>
                            <Checkbox
                              checked={checkRowSelection && checkRowSelection(row)}
                              onClick={e => {
                                e.stopPropagation();
                              }}
                              onChange={e => handleRowSelection(row)}
                            />
                          </Container>
                        )}
                        {columns.map((column, index) => {
                          if (column.field === 'rowActions') {
                            return (
                              <Container key={`columns-data-${index}`} className={column.width}>
                                <CustomStack direction='row' spacing={1}>
                                  {rowActions?.map((rowAction, index) => {
                                    const { onClick, showInMenu, isDisabled, label } = rowAction;
                                    const shouldHideRowAction = isDisabled && typeof isDisabled === 'function' ? isDisabled(row) : false;
                                    if (shouldHideRowAction) {
                                      return null;
                                    }
                                    return (
                                      !showInMenu && (
                                        <Container
                                          key={`row-actions-${index}`}
                                          // forwardRef={attributeRef}
                                          pointer>
                                          <span
                                            onClick={e => {
                                              e.stopPropagation();
                                              onClick(row);
                                            }}>
                                            {label}
                                          </span>
                                          {/* <Tippy content={label} reference={attributeRef} placement='bottom' /> */}
                                        </Container>
                                      )
                                    );
                                  })}
                                  {inMenuAction.length > 0 && inMenuAction.some(action => action.isDisabled(row) === false) && (
                                    <MoreOptions options={inMenuAction} argOnclick={row} width={'auto'} />
                                  )}
                                </CustomStack>
                              </Container>
                            );
                          }
                          const fieldValue = row[column.field];
                          const FieldComponent = typeof fieldValue === 'function' ? fieldValue : null;
                          const RenderCellComponent = column.renderCell ? column.renderCell({ row }) : null;
                          // if custom and need to hide specific column in a row
                          const isVisible = (!row.customSizeCols || row.customSizeCols[index]) && true;

                          return (
                            <Container
                              key={index}
                              className={row.customSizeCols && row.customSizeCols[index] ? row.customSizeCols[index] : column.width}
                              flex={isVisible}
                              none={!isVisible}
                              center={column.center}
                              opacity={!row.draft && !row.active && !tableInventory && !tableCustom ? '50%' : '100%'}>
                              {RenderCellComponent ? RenderCellComponent : FieldComponent ? <FieldComponent /> : fieldValue}
                            </Container>
                          );
                        })}
                      </Row>
                      {(!tableInventory || index !== rows.length - 1) && <Divider variant={!tableInventory ? 'middle' : undefined} />}
                    </>
                  );
                }}
              />
            )}
          </Container>
          {!tableInventory && <Divider />}
        </DataTableContainer>
      )}
    </>
  );
};

export default DataTable;

// Utils
export const addRowActions = (rowActions, columns, maximumRowActionsDisplayed) => {
  if (!columns.some(column => column.field === 'rowActions')) {
    columns.push({
      field: 'rowActions',
      headerName: '',
      width: 'col-1',
      bolder: true,
      center: false,
      // renderHeader: () => <React.Fragment />,
      // getActions: params => RowActions(params, rowActions, maximumRowActionsDisplayed),
    });
  }
};
